import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from "@angular/router";
import { AppService as appS } from "../app.service";
import { APIS } from "../shared/constants";
import { AppService } from "../services";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import moment from "moment";
import { RouteCheckService } from "../services/route-check/route-check.service";

@Component({
  selector: "app-left-side-bar",
  templateUrl: "./left-side-bar.component.html",
  styleUrls: ["./left-side-bar.component.scss"],
})
export class LeftSideBarComponent implements OnInit {
  @Output() menuOptions = new EventEmitter<any>();
  isIndividual = localStorage.getItem("isIndividual");
  menuItems = [
    {
      title: "Home",
      icon: "./assets/Navbar/Home.svg",
      showSubMenu: false,
      activeNumber: 0,
      isMain: true,
      isSearched: true,
      subMenu: [],
    },
    {
      title: "Profile",
      icon: "./assets/Navbar/avatar.svg",
      showSubMenu: false,
      activeNumber: 1,
      isMain: true,
      isSearched: true,
      subMenu: [
        { title: "View/Edit Profile", isMain: true },
        { title: "Recall", isMain: true },
        { title: "QA", isMain: true },
        // { title: 'View In App' ,  isMain:true},
        { title: "Plan Expiry", isMain: true },
        { title: "Health Parameters", isMain: true },
      ],
    },
    {
      title: "Diet plan",
      icon: "./assets/Navbar/document.svg",
      activeNumber: 2,
      showSubMenu: false,
      isMain: false,
      isSearched: true,
      subMenu: [
        { title: "Preferences", isMain: true },
        { title: "Food choices", isMain: true },
        { title: "7 days plan", isMain: true },
        { title: "Day by Day Plan", isMain: true },
      ],
    },
    {
      title: "Templates",
      icon: "./assets/Navbar/Gui.svg",
      activeNumber: 3,
      showSubMenu: false,
      isMain: false,
      isSearched: localStorage.getItem("femail") == "Yes",
      subMenu: [
        { title: "View Summary", isMain: true },
        { title: "Create", isMain: true },
      ],
    },

    {
      title: "Instructions",
      icon: "./assets/Navbar/instructions.svg",
      showSubMenu: false,
      activeNumber: 5,
      isMain: true,
      isSearched: true,
      subMenu: [{ title: "Instructions", isMain: true }],
    },
    {
      title: "Insights",
      icon: "./assets/Navbar/insights.svg",
      showSubMenu: false,
      activeNumber: 6,
      isMain: true,
      isSearched: true,
      subMenu: [{ title: "Summary", isMain: true }],
    },
    {
      title: "Slot remarks",
      icon: "./assets/Navbar/option8.svg",
      showSubMenu: false,
      activeNumber: 7,
      isMain: true,
      isSearched: true,
      subMenu: [{ title: "Insights", isMain: true }],
    },
    {
      title: "Go to Beato",
      icon: "./assets/Navbar/external.svg",
      showSubMenu: false,
      activeNumber: 8,
      isMain: true,
      isSearched: true,
      subMenu: [{ title: "Beato", isMain: true }],
    },
    {
      title: "Support",
      icon: "./assets/Navbar/external.svg",
      showSubMenu: false,
      activeNumber: 9,
      isMain: true,
      isSearched: true,
      subMenu: [
        { title: "WhatsApp", isMain: true },
        { title: "FAQ", isMain: true },
        { title: "Premium Plan", isMain: true },
        // { title: "Email Us", isMain: true }
      ],
    },
  ];
  menuItemsWithoutLogin = [
    {
      title: "Home",
      icon: "./assets/Navbar/Home.svg",
      showSubMenu: false,
      activeNumber: 1,
      isMain: true,
      isSearched: true,
      subMenu: [],
    },
    {
      title: "Templates",
      icon: "./assets/Navbar/profile.svg",
      activeNumber: 3,
      showSubMenu: false,
      isMain: false,
      subMenu: [
        { title: "View Summary", isMain: true },
        { title: "Create", isMain: true },
      ],
    },
  ];
  username = "";
  activeRoute: number = Number(
    localStorage.getItem("activeNum") === null
      ? 2
      : localStorage.getItem("activeNum")
  );
  profileData: any = {};
  isBeato = localStorage.getItem("loginEmail")
    ? localStorage.getItem("loginEmail").toLowerCase().includes("beatoapp.com")
    : false;
  search: any = localStorage.getItem("email");
  @Input() cURL: string;
  recall: any = "false";
  inSights = "false";
  templateId: any = "";
  isEdit: any = "false";
  isSearched = localStorage.getItem("femail") == "Yes";
  constructor(
    private router: Router,
    private appServe: AppService,
    private appservice: appS,
    private iab: InAppBrowser,
    private queryParams: ActivatedRoute,
    private routeCheckService: RouteCheckService,
    private activeRouter: ActivatedRoute
  ) {
    this.queryParams.queryParams.subscribe((res) => {
      this.recall = res["recall"];
      this.templateId = res["params"];
      this.isEdit = res["edit"];
      if (res["insights"]) {
        this.inSights = res["insights"];
      }
    });
    this.getProfile();
  }
  showSubMenu(item) {
    item.showSubMenu = true;
  }

  hideSubMenu(item) {
    item.showSubMenu = false;
  }

  ngOnInit(): void {
    // this.searchData = "";
    this.username = "";
    this.username = this.getUserName();
    this.updateMenuTitles();
    this.search = localStorage.getItem("email");
    // alert(this.cURL);
    // console.log(">> .state URllll ---->>> ", this.cURL);
    this.routeCheckService.currentUrl$.subscribe((url) => {
      // alert(url);
      console.log("Current Route:", url);
      if (url === "/preference-diet") {
        this.activeRoute = 2;
      } else if (url === "/dashboard") {
        if (this.getIsSearched()) {
          this.activeRoute = 0;
        } else {
          this.activeRoute = 1;
        }
      }
      if (url !== null) this.getActiveRoute(url);
      // You can use this.currentRoute to manage the state
      // this.isLogged = url!=="/login";
    });
  }

  getUserName() {
    const email = localStorage.getItem("loginEmail");
    return email.toLowerCase().includes("beatoapp.com".toLowerCase())
      ? "beato"
      : email;
  }

  updateMenuTitles() {
    if (localStorage.getItem("companyId") === "smartdietplanner") {
      this.menuItems.forEach((menuItem) => {
        if (menuItem.title === "Profile") {
          menuItem.subMenu.push({ title: "View In App", isMain: true });
        }
      });
    }
    if (this.username === "beato") {
      this.menuItems.forEach((menuItem) => {
        if (menuItem.title === "Profile") {
          menuItem.subMenu = menuItem.subMenu.filter(
            (item) => item.title === "View/Edit Profile"
          );
        }
      });
    }
    if (this.isIndividual === "true") {
      this.menuItems.forEach((menuItem) => {
        if (menuItem.title === "Profile") {
          menuItem.subMenu = menuItem.subMenu.filter(
            (item) => item.title !== "Plan Expiry"
          );
        }
      });
    }
  }

  gotoTransition(item, submenu) {
    this.activeRoute = item.activeNumber;
    localStorage.setItem("activeNum", item.activeNumber);
    if (item.title === "Recall" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (item.title === "Instructions" && submenu === true) {
      this.router.navigate(["/add-instructions"]);
    } else if (item.title === "QA" && submenu === true) {
      this.router.navigate(["/consult-qa"]);
    } else if (item.title === "Health Parameters" && submenu === true) {
      this.router.navigate(["/consult-qa"]);
    } else if (item.title === "Details" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { insights: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (item.title === "Slot remarks" && submenu === true) {
      this.router.navigate(["update-slot-remarks"], {
        queryParams: { params: this.search },
      });
    } else if (item.title === "Go to Beato" && submenu === true) {
      const start_date = moment(new Date()).format("YYYY-MM-DD");
      const end_date = moment(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() + 7
        )
      ).format("YYYY-MM-DD");
      console.log(
        `https://care.internal.beatoapp.com/diet-chart-listing?userid=${this.search}&flag=0&start=${start_date}&end=${end_date}`
      );

      window.open(
        `https://care.internal.beatoapp.com/diet-chart-listing?userid=${this.search}&flag=0&start=${start_date}&end=${end_date}`,
        "_blank"
      );
    } else if (item.title === "Recall" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (item.title === "Recall" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (item.title === "Recall" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (item.title === "Home" && submenu === true) {
      localStorage.setItem("menu", "");
      this.router.navigate(["dashboard"]).then(() => {
        window.location.reload();
      });
    } else if (item.title === "WhatsApp" && submenu === true) {
      const phoneNumber = "+919999118595"; // Replace with the actual WhatsApp number
      const email = localStorage.getItem("loginEmail");
      const name = localStorage.getItem("dietitianName");
      // alert("email: "+email + " :: Name: " + name)
      const message = encodeURIComponent(
        name
          ? `I am ${name} with email id ${email} and I need support`
          : `I am ${email} and I need support`
      );
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
      // const whatsappUrl = `https://wa.me/message/KGHV4SZOZ424J1`;
      window.open(whatsappUrl, "_blank");
    } else if (item.title === "FAQ" && submenu === true) {
      this.router.navigate(["faq"]);
    } else if (item.title === "Premium Plan" && submenu === true) {
      this.router.navigate(["dietitian-plan"]);
    } else if (item.title === "Email Us" && submenu === true) {
      // alert("Clicked")
      // const url = "mailto:dietitian@smartdietplanner.com?subject=New%20User&body=Hello%20Fitrofy";
      // window.open(url, "_blank");
    }
  }

  gotoTransitionSub(item, title, submenu) {
    this.activeRoute = item.activeNumber;
    this.menuOptions.emit(
      { item, title }
      // "<span>" +
      //   item.title +
      //   "</span> - <span>" +
      //   title +
      //   "</span><ion-icon name='informaion'></ion-icon>"
    );
    localStorage.setItem("activeNum", item.activeNumber);
    if (title === "View/Edit Profile" && submenu === true) {
      this.router.navigate(["/profile-detail"]);
    }
    if (title === "Health Parameters" && submenu === true) {
      this.router.navigate(["/health-parameters"]);
    }
    if (title === "Recall" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    }
    if (title === "QA" && submenu === true) {
      this.router.navigate(["/consult-qa"]);
    }
    if (title === "View In App" && submenu === true) {
      this.viewApp();
    }
    if (title === "Plan Expiry" && submenu === true) {
      this.gotoPlanExpiry();
    }
    if (title === "Summary" && submenu === true) {
      this.gotoInsights();
    }

    if (title === "Preferences" && submenu === true) {
      this.router.navigate(["/preference-diet"]);
    } else if (title === "Food choices" && submenu === true) {
      this.router.navigate(["personalise"]);
    } else if (title === "7 days plan" && submenu === true) {
      this.router.navigate(["manage-diet"]).then(() => {
        window.location.reload();
      });
    } else if (title === "Day by Day Plan" && submenu === true) {
      if (this.username !== "beato") {
        this.router.navigate(["/consume"]);
      } else {
        this.router.navigate(["/consume"]);
      }
    } else if (title === "View Summary" && submenu === true) {
      this.router.navigate(["/template"]);
    } else if (title === "Create" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { newTemplate: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (title === "Plan Expiry -Release Plan" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (title === "View in app" && submenu === true) {
      this.router
        .navigate(["manage-diet"], { queryParams: { recall: true } })
        .then(() => {
          window.location.reload();
        });
    } else if (title === "WhatsApp" && submenu === true) {
      const phoneNumber = "+919999118595"; // Replace with the actual WhatsApp number
      const email = localStorage.getItem("loginEmail");
      const name = localStorage.getItem("dietitianName");
      // alert("email: "+email + " :: Name: " + name)
      const message = encodeURIComponent(
        name
          ? `I am ${name} with email id ${email} and I need support`
          : `I am ${email} and I need support`
      );
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
      // const whatsappUrl = `https://wa.me/message/KGHV4SZOZ424J1`;
      window.open(whatsappUrl, "_blank");
    } else if (title === "FAQ" && submenu === true) {
      this.router.navigate(["faq"]);
    } else if (title === "Premium Plan" && submenu === true) {
      this.router.navigate(["dietitian-plan"]);
    } else if (item.title === "Email Us" && submenu === true) {
      // this.router.navigate(["faq"]);
      // alert("Clicked")
      const url =
        "mailto:dietitian@smartdietplanner.com?subject=New%20User&body=Hello%20Fitrofy";
      this.iab.create(url, "_system", "location=yes");
      // window.open(url, "_blank");
    }
  }
  getActiveRoute(url: string) {
    // alert(`{URl>> ${url}}`);
    if (url.includes("/deitician-search") || url.includes("/preference-diet")) {
      // Do something if URL contains '/deitician-search'
      console.log("Deitician search page");
    } else if (url.includes("/food-item-choice")) {
    } else if (url.includes("/personalise")) {
    } else if (url.includes("/consult-qa")) {
    } else if (url.includes("/add-instructions")) {
    } else if (url.includes("/consume")) {
    } else if (url.includes("/update-slot-remarks")) {
    } else if (url.includes("/plan-expiry")) {
    } else if (url.includes("/template")) {
    } else if (url.includes("/manage-diet")) {
      if (url === "/manage-diet?recall=true") {
      } else if (url === "/manage-diet?insights=true") {
      } else {
      }
    }
  }

  getProfile() {
    this.profileData = [];
    this.appservice.getProfile().then((res) => {
      this.profileData = res;
      console.log(this.profileData);
    });
  }

  goToClientApp(token) {
    let link = APIS.mainUrl + "?token=" + token;
    console.log("link", link);
    this.iab.create(link, "_system", "location=yes");
  }
  goto7DaysPlan() {
    this.router.navigate(["manage-diet"]).then(() => {
      window.location.reload();
    });
  }
  gotoRecall() {
    this.router
      .navigate(["manage-diet"], { queryParams: { recall: true } })
      .then(() => {
        window.location.reload();
      });
  }
  gotoInsights() {
    this.router
      .navigate(["calory-chart"], { queryParams: { insights: true } })
      .then(() => {
        window.location.reload();
      });
  }
  getToken() {
    if (!localStorage.getItem("email")) {
      // alert("Please Enter Customer ID");
      return;
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.goToClientApp(error.error.text);
          localStorage.setItem("tknupdatetarget", error.error.text);
          console.log("Category Error", error);
        }
      );
    }
  }
  gotoupdateRemarks() {
    // goto slot remarks update
    this.router.navigate(["update-slot-remarks"], {
      queryParams: { params: this.search },
    });
  }
  gotoDietPlan() {
    if (this.username !== "beato") {
      this.router.navigate(["/consume"]);
    } else {
      this.router.navigate(["/consume"]);
    }
  }
  gotoPreferences() {
    this.router.navigate(["/preference-diet"]).then((res) => {
      location.reload();
    });
  }
  gotoBeato() {
    const start_date = moment(new Date()).format("YYYY-MM-DD");
    const end_date = moment(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 7
      )
    ).format("YYYY-MM-DD");
    console.log(
      `https://care.internal.beatoapp.com/diet-chart-listing?userid=${this.search}&flag=0&start=${start_date}&end=${end_date}`
    );

    window.open(
      `https://care.internal.beatoapp.com/diet-chart-listing?userid=${this.search}&flag=0&start=${start_date}&end=${end_date}`,
      "_blank"
    );
  }
  updateFoodChoices() {
    this.router.navigate(["personalise"]);
  }
  consultQA() {
    this.router.navigate(["/consult-qa"]);
  }
  AddInstructions() {
    this.router.navigate(["/add-instructions"]);
  }
  gotoPlanExpiry() {
    this.router.navigate(["/plan-expiry"]);
  }
  gotoTemplate() {
    this.router.navigate(["/template"]);
  }
  getIsSearched(): boolean {
    return localStorage.getItem("femail") == "Yes";
  }

  viewApp() {
    if (!localStorage.getItem("email")) {
      // alert("Please Enter Customer ID");
      return;
    } else {
      this.appServe.getUserToken(localStorage.getItem("email")).subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("User token ", error.error.text);
          this.goToClientApp(error.error.text);
          localStorage.setItem("tknupdatetarget", error.error.text);
          console.log("Category Error", error);
        }
      );
    }
  }
  PlanExpiry() {
    this.router.navigate(["/plan-expiry"]);
  }
}
